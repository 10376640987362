.highlight-wrapper {
    position: relative;
}

.highlight-link {
    position: absolute;
    bottom: 0;
    right: 0;
}

.highlight-before {
    border-top: 1px dotted #666;
    border-left: 1px dotted #666;
    border-right: 1px dotted #666;
    padding: 0  5px;
}