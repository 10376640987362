$color-background: #1d1f21;
$color-footer-mobile-1: lighten($color-background, 2%);
$color-footer-mobile-2: lighten($color-background, 10%);
$color-background-code: lighten($color-background, 2%);
$color-border: #666;
$color-meta: #666;
$color-meta-code: #666;
$color-link: rgba(212, 128, 170, 1);
$color-text: #c9cacc;
$color-accent-3: #cccccc;
$color-accent-2: #eeeeee;
$color-accent-1: #2bbc8a;
$color-quote: #ccffb6;